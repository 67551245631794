import { Icon } from '@chakra-ui/react';

export function EarthIcon(props) {
  return (
    <Icon viewBox="0 0 40 40" w="40px" h="40px" fill="none" {...props}>
      <circle cx="20" cy="20" r="11" stroke="#F6C76A" stroke-width="2" />
      <path
        d="M24.455 20C24.455 23.2046 23.8625 26.0573 22.947 28.0715C21.9905 30.1759 20.8734 31 20.0004 31C19.1275 31 18.0104 30.1759 17.0539 28.0715C16.1383 26.0573 15.5459 23.2046 15.5459 20C15.5459 16.7954 16.1383 13.9427 17.0539 11.9285C18.0104 9.82409 19.1275 9 20.0004 9C20.8734 9 21.9905 9.82409 22.947 11.9285C23.8625 13.9427 24.455 16.7954 24.455 20Z"
        stroke="#F6C76A"
        stroke-width="2"
      />
      <mask
        id="mask0_1028_2498"
        maskUnits="userSpaceOnUse"
        x="8"
        y="8"
        width="24"
        height="24"
      >
        <circle cx="20" cy="20" r="12" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_1028_2498)">
        <path
          d="M33.182 13.4545C33.182 13.829 32.9885 14.2979 32.3947 14.8378C31.8007 15.3781 30.8852 15.9105 29.6693 16.3781C27.244 17.311 23.8257 17.9091 20.0002 17.9091C16.1746 17.9091 12.7564 17.311 10.3311 16.3781C9.11511 15.9105 8.19969 15.3781 7.60561 14.8378C7.01181 14.2979 6.81836 13.829 6.81836 13.4545C6.81836 13.0801 7.01181 12.6112 7.60561 12.0712C8.19969 11.531 9.11511 10.9986 10.3311 10.5309C12.7564 9.59814 16.1746 9 20.0002 9C23.8257 9 27.244 9.59814 29.6693 10.5309C30.8852 10.9986 31.8007 11.531 32.3947 12.0712C32.9885 12.6112 33.182 13.0801 33.182 13.4545Z"
          stroke="#F6C76A"
          stroke-width="2"
        />
        <mask
          id="mask1_1028_2498"
          maskUnits="userSpaceOnUse"
          x="7"
          y="21"
          width="26"
          height="6"
        >
          <rect
            x="7.4541"
            y="21.0908"
            width="25.0909"
            height="5.45455"
            fill="#D9D9D9"
          />
        </mask>
        <g mask="url(#mask1_1028_2498)">
          <path
            d="M33.181 19.2727C33.181 19.7035 32.969 20.2125 32.376 20.7787C31.7805 21.3473 30.8657 21.9054 29.6528 22.3952C27.2323 23.3727 23.8197 24 19.9992 24C16.1787 24 12.7661 23.3727 10.3456 22.3952C9.13274 21.9054 8.21792 21.3473 7.62243 20.7787C7.02943 20.2125 6.81738 19.7035 6.81738 19.2727C6.81738 18.8419 7.02943 18.3328 7.62243 17.7666C8.21792 17.1981 9.13274 16.6399 10.3456 16.1501C12.7661 15.1726 16.1787 14.5454 19.9992 14.5454C23.8197 14.5454 27.2323 15.1726 29.6528 16.1501C30.8657 16.6399 31.7805 17.1981 32.376 17.7666C32.969 18.3328 33.181 18.8419 33.181 19.2727Z"
            stroke="#F6C76A"
            stroke-width="2"
          />
        </g>
      </g>
    </Icon>
  );
}
