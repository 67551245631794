const colors = {
  grey: {
    800: '#13181B',
    700: '#202020',
    600: '#323232',
    500: '#666666',
    400: '#999999',
    300: '#323232',
    200: '#EFEFEF',
    100: '#F0F0F0',
  },
  cloud: {
    500: '#D5DDDE',
  },
  aquaGreen: {
    500: '#204F55',
    600: '#263036',
  },
  yellow: {
    500: '#F6C76A',
  },
  orange: {
    400: '#ED662D',
    500: '#ED662D',
    600: '#ED662D',
  },
};

export default colors;
